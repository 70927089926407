import { computed, ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import store from '@/store'
import UserEntity from '../../../entities/user.entity'
import UserController from '@/controllers/user.controller'

export default function useUsersList() {
  /**
   * Property that defines a reference to the users table.
   */
  const refUsersListTable = ref(null)

  /**
   * Property that defines the searching string from the user list.
   *
   * @default ''
   */
  const search = ref('')

  /**
   * Property that defines the column that will be used to sort the
   * table.
   *
   * @default 'id'
   */
  const sortBy = ref('createdAt')

  /**
   * Property that defines whether the sort will be ascending or
   * descending.
   *
   * @default true
   */
  const isSortDirDesc = ref(true)

  /**
   * Property that defines the table current page.
   *
   * @default 1
   */
  const currentPage = ref(1)

  const perPageOptions = [10, 25, 50, 100]
  const perPage = 10

  /**
   * Property that stores the fetched users.
   *
   * @type {import('@vue/composition-api').ComputedRef<UserEntity[]>}
   */
  const users = computed({
    get: () => store.state.user.users,
  })

  /**
   * Property that defines the users list headers.
   */
  const tableColumns = computed(() => {
    return [
      {
        key: 'role',
        label: i18n.t('admin.labs.listLabs.userHeaders.role'),
        sortable: true,
      },
      {
        key: 'name',
        label: i18n.t('admin.labs.listLabs.userHeaders.name'),
        sortable: true,
      },
      {
        key: 'lastName',
        label: i18n.t('admin.labs.listLabs.userHeaders.lastName'),
        sortable: true,
      },
      {
        key: 'email',
        label: i18n.t('admin.labs.listLabs.userHeaders.email'),
        sortable: true,
      },
      {
        key: 'status',
        label: i18n.t('admin.labs.listLabs.userHeaders.status'),
        sortable: true,
      },
      {
        key: 'updatedAt',
        label: i18n.t('admin.labs.listLabs.userHeaders.updatedAt'),
        sortable: true,
      },
      {
        key: 'createdAt',
        label: i18n.t('admin.labs.listLabs.userHeaders.createdAt'),
        sortable: true,
      },
      // {
      //   key: 'phoneNumber',
      //   label: i18n.t('admin.labs.listLabs.userHeaders.phone'),
      //   sortable: true,
      // },
      // {
      //   key: 'address.line1',
      //   label: i18n.t('admin.labs.listLabs.userHeaders.addressLine1'),
      //   sortable: true,
      // },
      // {
      //   key: 'address.line2',
      //   label: i18n.t('admin.labs.listLabs.userHeaders.addressLine2'),
      //   sortable: true,
      // },
      // {
      //   key: 'address.country',
      //   label: i18n.t('admin.labs.listLabs.userHeaders.country'),
      //   sortable: true,
      // },
      // {
      //   key: 'address.zipCode',
      //   label: i18n.t('admin.labs.listLabs.userHeaders.zip'),
      //   sortable: true,
      // },
      // {
      //   key: 'address.number',
      //   label: i18n.t('admin.labs.listLabs.userHeaders.number'),
      //   sortable: true,
      // },
      {
        key: 'actions',
        label: i18n.t('admin.labs.listLabs.userHeaders.actions'),
        sortable: false,
      },
    ]
  })

  /**
   * Deletes an user from the database.
   *
   * @param {string} id the user id.
   */
  const deleteUser = async (company, email, id) => {
    try {
      await store.dispatch('user/deleteUser', {
        path: `users/${id}`,
        userId: store.state.auth.userId,
      })
      store.dispatch('user/fetchUsers')

      const emailData = {
        company: company,
        toEmail: email,
        subject: 'Acesso Bloqueado',
        name: 'Lensxpert',
        fromEmail: '',
      }
      await new UserController().sendAccountBlockedEmail(emailData)
    } catch (e) {
      console.error(e)
    }
  }

  /**
   * Restores an user from the database.
   *
   * @param {string} id the user id.
   */
  const restoreUser = async (company, email, id) => {
    try {
      await store.dispatch('user/restoreUser', `users/${id}`)
      store.dispatch('user/fetchUsers')

      const emailData = {
        company: company,
        toEmail: email,
        subject: 'Acesso Restaurado!',
        name: 'Lensxpert',
        fromEmail: '',
      }
      await new UserController().sendAccountReactivatedEmail(emailData)
    } catch (e) {
      console.error(e)
    }
  }

  return {
    currentPage,
    isSortDirDesc,
    refUsersListTable,
    search,
    sortBy,
    tableColumns,
    deleteUser,
    restoreUser,
    users,
    perPage,
    perPageOptions,
  }
}
