<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('admin.labs.sellersList.show') }}</label>

            <v-select
              v-model="perPage"
              class="per-page-selector d-inline-block mx-50"
              :options="perPageOptions"
              :clearable="false"
            />

            <label>{{ $t('admin.labs.sellersList.entries') }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="search"
                class="d-inline-block"
                :placeholder="$t('admin.labs.sellersList.searchByEmail')"
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUsersListTable"
        responsive
        show-empty
        primary-key="id"
        empty-text="No entries"
        class="position-relative"
        :items="filteredItems"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        :busy="$store.state.user.loadingUsers"
        :per-page="perPage"
        :current-page="currentPage"
      >
        <!-- Table Loading -->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle" small />

            <strong class="ml-1">Loading...</strong>
          </div>
        </template>

        <template #cell(role)="data">
          <b-badge :variant="getRoleVariant(data.item.role)">
            {{ getRoleTitle(data.item.role) }}
          </b-badge>
        </template>

        <template #cell(createdAt)="data">
          <span
            >{{ beautyParserDateToString(data.item.createdAt) }} <br />
            {{ beautyParserHourToString(data.item.createdAt) }}</span
          >
        </template>

        <template #cell(updatedAt)="data">
          <span
            >{{ beautyParserDateToString(data.item.updatedAt) }} <br />
            {{ beautyParserHourToString(data.item.updatedAt) }}</span
          >
        </template>

        <template #cell(status)="data">
          <b-badge :variant="getStatusVariant(data.item.status)">
            <span>{{ getStatusText(data.item.status) }}</span>
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-row align-h="end">
            <b-button
              size="sm"
              class="ml-1"
              variant="outline-primary"
              :id="`view-${data.item.id}`"
              v-if="$store.getters['auth/inactive'] || !data.item.deletedAt"
              @click="
                $router.push({
                  name: 'users-view',
                  params: { id: data.item.id },
                })
              "
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
            <b-tooltip
              triggers="hover"
              placement="bottom"
              :target="`view-${data.item.id}`"
            >
              <b>View</b>
            </b-tooltip>
            <b-button
              size="sm"
              class="ml-1"
              variant="outline-secondary"
              :id="`edit-${data.item.id}`"
              v-if="$store.getters['auth/inactive'] || !data.item.deletedAt"
              @click="
                $router.push({
                  name: 'users-edit',
                  params: { id: data.item.id },
                })
              "
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-tooltip
              triggers="hover"
              placement="bottom"
              :target="`edit-${data.item.id}`"
            >
              <b>Edit</b>
            </b-tooltip>

            <b-button
              size="sm"
              class="ml-1"
              :id="`delete-${data.item.id}`"
              :variant="
                canRestore(data.item) ? 'outline-success' : 'outline-danger'
              "
              :disabled="
                $store.getters['auth/inactive'] ||
                (data.item.deletedAt &&
                  data.item.deletedBy !== $store.state.auth.userId)
              "
              @click="
                () => {
                  if (data.item.deletedAt) {
                    return restoreUser(
                      data.item.name,
                      data.item.email,
                      data.item.id,
                    )
                  }

                  selectedItem = data.item
                  deleteModal = true
                }
              "
            >
              <feather-icon v-if="canRestore(data.item)" icon="RotateCcwIcon" />
              <feather-icon v-else icon="SlashIcon" />
            </b-button>
            <b-tooltip
              triggers="hover"
              placement="bottom"
              :target="`delete-${data.item.id}`"
            >
              <b>{{
                canRestore(data.item)
                  ? $t('admin.labs.listLabs.restore')
                  : $t('admin.labs.listLabs.delete')
              }}</b>
            </b-tooltip>
          </b-row>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              {{ $t('admin.labs.sellersList.totalOf') }} {{ totalItems }}
              {{ $t('admin.labs.sellersList.entries') }}
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              :per-page="perPage"
              :total-rows="totalItems"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <AlertDialog
      :id="`confirmation-${selectedItem.id}`"
      :show="deleteModal"
      :message="$t('admin.labs.userList.deleteDialog.message')"
      :title="`${$t('admin.labs.userList.deleteDialog.title')} ${
        selectedItem.name
      }`"
      @action="
        deleteUser(selectedItem.name, selectedItem.email, selectedItem.id)
        selectedItem = {}
      "
      @close="deleteModal = false"
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BLink,
  BBadge,
  BPagination,
  BSpinner,
  BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import AlertDialog from '@/components/AlertDialog.vue'

import { onUnmounted } from 'vue-demi'
import store from '@/store'

import userStoreModule from './userStoreModule'
import useUsersList from './useUsersList'
import RoleEnum from '@/utils/roles'

import {
  beautyParserDateToString,
  beautyParserHourToString,
} from '@/utils/dates'

export default {
  name: 'UsersList',

  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BPagination,
    BSpinner,
    BTooltip,

    vSelect,
    AlertDialog,
  },

  data() {
    return {
      deleteModal: false,
      selectedItem: {},
      beautyParserDateToString,
      beautyParserHourToString,
    }
  },

  computed: {
    filteredItems() {
      return this.users.filter((el) =>
        el.email.toLowerCase().includes(this.search.toLowerCase()),
      )
    },
    totalItems() {
      return this.filteredItems.length || 0
    },
  },
  methods: {
    canRestore(item) {
      return item.deletedAt && item.deletedBy === this.$store.state.auth.userId
    },
    getRoleTitle(roleN) {
      return RoleEnum.getRoleTitle(roleN)
    },
    getRoleVariant(roleN) {
      return RoleEnum.getRoleVariant(roleN)
    },
    getStatusVariant(status) {
      switch (status) {
        case 'active':
          return 'success'
        case 'pending':
          return 'warning'
        case 'pending-authorization':
          return 'danger'
        case 'pending-payment':
          return 'info'
        default:
          return 'warning'
      }
    },
    getStatusText(status) {
      switch (status) {
        case 'active':
          return this.$t('status.active')
        case 'pending':
          return this.$t('status.pending')
        case 'pending-authorization':
          return this.$t('status.requested')
        case 'pending-payment':
          return this.$t('status.payment')
        default:
          return this.$t('status.pending')
      }
    },
  },

  setup() {
    const USER_APP_STORE_MODULE_NAME = 'user'

    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    }

    onUnmounted(
      () =>
        store.hasModule(USER_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(USER_APP_STORE_MODULE_NAME),
    )

    store.dispatch('user/fetchUsers')

    const {
      currentPage,
      isSortDirDesc,
      refUsersListTable,
      search,
      sortBy,
      tableColumns,
      users,
      deleteUser,
      restoreUser,
      perPage,
      perPageOptions,
    } = useUsersList()

    return {
      currentPage,
      isSortDirDesc,
      refUsersListTable,
      search,
      sortBy,
      tableColumns,
      users,
      deleteUser,
      restoreUser,
      perPage,
      perPageOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
